// This is the scss entry file
import "../styles/index.scss";
import "bootstrap/dist/js/bootstrap.bundle";
require("bootstrap-icons/font/bootstrap-icons.css");
import "./finisher-header.es5.min.js";
// var bodymovin = require('bodymovin');
// var lottie = require('lottie-web');

// import htmx from "htmx.org";
window.htmx = require("htmx.org");

// catch htmx errors
document.body.addEventListener("htmx:responseError", function(e) {
    let error = e.detail.xhr.response;
    // get error code
    let code = e.detail.xhr.status;
    console.log(code);
    // if status is 413, then the request was too large
    if (code == 413) {
        error = "The file you uploaded was too large. Please try again with a smaller file.";
    }
    // #errors is the id of the div that will display the error
    let ERRORCONT = document.querySelector("#errors");
    ERRORCONT.innerHTML = error;
    // flicker the error message

});

// htmx.defineExtension('disable-element', {
//     onEvent: function (name, evt) {
//         console.log("disable-element");
//         let elt = evt.detail.elt;
//         let target = elt.getAttribute("hx-disable-element");
//         let targetElement = (target == "self") ? elt : document.querySelector(target);

//         if (name === "htmx:beforeRequest" && targetElement) {
//             targetElement.disabled = true;
//         } else if (name == "htmx:afterRequest" && targetElement) {
//             targetElement.disabled = false;
//         }
//     }
// });

// import htmx debug  htmx/debug.js
// import "htmx.org/dist/ext/debug.js";
// window.debug = require('htmx.org/dist/ext/debug.js');

// We can import Bootstrap JS instead of the CDN link, if you do not use
// Bootstrap, please feel free to remove it.
// import "bootstrap/dist/js/bootstrap.bundle";

// We can import other JS file as we like
// import "../components/sidebar";

// window.document.addEventListener("DOMContentLoaded", function () {
//   window.console.log("dom ready 1");
// });

// import { createApp } from 'vue';
// import App from '../components/App.vue';

// createApp(App).mount('#app');

// new FinisherHeader({
//   "count": 10,
//   "size": {
//     "min": 923,
//     "max": 1069,
//     "pulse": 1.2
//   },
//   "speed": {
//     "x": {
//       "min": 1,
//       "max": 1.6
//     },
//     "y": {
//       "min": 1.5,
//       "max": 2.4
//     }
//   },
//   "colors": {
//     "background": "#22296c",
//     "particles": [
//       "#2a4995",
//       "#373317",
//       "#112e62",
//       "#1b3378",
//       "#313499"
//     ]
//   },
//   "blending": "lighten",
//   "opacity": {
//     "center": 0.8,
//     "edge": 0.5
//   },
//   "skew": 2,
//   "shapes": [
//     "c",
//     "t"
//   ]
// });

// on clicking #logout
// document.querySelector("#logout").addEventListener("click", function (e) {
//     e.preventDefault();
//     // send a POST request to /logout using fetch
//     fetch("/logout", {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//         },
//         body: JSON.stringify({}),
//     })
//         .then(
//             function (response) {
//                 return response.json();
//             }
//             // if successful, reload the page
//         )
//         .then(
//             function (data) {
//                 window.location.reload();
//             }
//             // if unsuccessful, log the error
//         )
//         .catch(
//             function (error) {
//                 console.log("Error:", error);
//             }
//             // always reload the page
//         )
//         .finally(function () {
//             window.location.reload();
//         });
// });

// on htmx load
htmx.onLoad(function (content) {
    // check if finisher-header class exists
    if (document.querySelector(".finisher-header")) {
        new FinisherHeader({
            count: 10,
            size: {
                min: 923,
                max: 1069,
                pulse: 1.2,
            },
            speed: {
                x: {
                    min: 1,
                    max: 1.6,
                },
                y: {
                    min: 1.5,
                    max: 2.4,
                },
            },
            colors: {
                background: "#22296c",
                particles: [
                    "#2a4995",
                    "#373317",
                    "#112e62",
                    "#1b3378",
                    "#313499",
                ],
            },
            blending: "lighten",
            opacity: {
                center: 0.8,
                edge: 0.5,
            },
            skew: 2,
            shapes: ["c", "t"],
        });
    } 

});

// select #woocommerce button
const woocommerce = document.querySelector('#woocommerce');

htmx.on(woocommerce, "click", function(evt){
    const title = document.querySelector('#title_text');
    const description = document.querySelector('#description_text');
    const image_url = document.querySelector('#image_url');
    const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    // create a new form data object
    const formData = new FormData();
    // append the title to the form data
    formData.append('title', title.value);
    // append the description to the form data
    formData.append('description', description.value);
    // append the image url to the form data
    formData.append('image_url', image_url.value);
    // append the django csrf token to the form data
    formData.append('csrfmiddlewaretoken', csrf_token);
    
    // send the data to the server
    fetch('/sendto_woocommerce', {
        method: 'POST',
        body: formData
    })
    .then(response => response.text())
    .then(data => {
        // redirect to the dashboard
        // window.location.href = '/dashboard';
        console.log(data);
        // response returns html, insert after #woocommerce
        document.querySelector('#woocommerce').insertAdjacentHTML('afterend', data);
    })
    .catch(error => {
        console.error(error);
    });
});
